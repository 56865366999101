const kycModuleTypeIdMap: Record<string, string> = {
  1: 'Selfie Capture',
  2: 'PAN Doc Capture front',
  3: 'PAN field with DB name match',
  4: 'PAN',
  5: 'PAN and Selfie Face Match',
  6: 'Aadhaar field with DB name match',
  7: 'Aadhaar Digi Locker',
  8: 'Face Match with Digi locker record',
  9: 'Aadhaar Doc capture front',
  10: 'Aadhaar Doc capture back',
  11: 'Aadhaar XML',
  12: 'Aadhaar & Selfie Match',
  13: 'Aadhaar & Pan Match',
};

const kycTypeIdMap: Record<string, string> = {
  1: 'Selfie',
  2: 'Pan',
  3: 'Aadhaar',
};

export const paymentStatusTextMap: Record<string, string> = {
  Initiated: 'INITIATED',
  Charging: 'CHARGING',
  Received: 'RECEIVED',
  Failed: 'FAILED',
  'Refund Initiated': 'REFUND_INITIATED',
  'Refund Failed': 'REFUND_FAILED',
  Refunded: 'REFUND_COMPLETED',
  'Settlement Initiated': 'SETTLEMENT_INITIATED',
  Settled: 'SETTLEMENT_COMPLETED',
  'Settlement Failed': 'SETTLEMENT_FAILED',
  'On Hold': 'ON_HOLD',
  Cancelled: 'CANCELLED',
  Success: 'SUCCESS',
};

export enum PaymentGateways {
  'RAZORPAY' = 1,
  'PLURAL' = 2,
  'PAYOMATIX' = 3,
  'MANUAL' = 4,
  'RUNPAISA' = 5,
}

export const mids: Record<
  string,
  {
    email: string;
    fee: number;
    details: string;
  }
> = {
  MID92528: {
    email: 'sharjeelkausari@gmail.com',
    fee: 0.71,
    details: 'Razorpay Education',
  },
  MID40212: {
    email: 'khanamanulla172@gmail.com',
    fee: 0.78,
    details: 'Razorpay Electricity',
  },
  MID54686: {
    email: 'SALMAN.KHAN76@GMAIL.COM',
    fee: 0.78,
    details: 'Razorpay Services/Fastag',
  },
  MID88964: {
    email: 'fahadaslam007007@gmail.com',
    fee: 0.85,
    details: 'Razorpay LIC',
  },
  MID35141: {
    email: 'aslamfardeen670@gmail.com',
    fee: 1.15,
    details: 'Razorpay Rent',
  },
  MID63265: {
    email: 'manual',
    fee: 1.3,
    details: 'Manual Link',
  },
  MID72301: {
    email: 'runpaisa',
    fee: 1.3,
    details: 'Runpaisa',
  },
  MID51543: {
    email: 'masterpay',
    fee: 1.2,
    details: 'MasterPay',
  },
  MID18865: {
    email: 'payomatix',
    fee: 1.1,
    details: 'Payomatix (Airpay)',
  },
  MID31763: {
    email: 'sabpaisa',
    fee: 1.9,
    details: 'SabPaisa',
  },
  MID41117: {
    email: 'pinelabs',
    fee: 0.9,
    details: 'PineLabs Utility',
  },
  MID7670: {
    email: 'pinelabs',
    fee: 1.2,
    details: 'PineLabs Rental',
  },
};

const formatData = (resource: string, data: any) => {
  switch (resource) {
    case 'banks':
      return {
        ...data,
        id: data.bankId,
        dbId: data.id,

      };
    case 'cardProviders':
      return {
        ...data,
        id: data.cardProviderId,
        dbId: data.id,
      };
    case 'customers':
      return {
        ...data,
        id: data.userId,
        createdAt: new Date(data.createdAt * 1000),
        kycStatus: data.kycStatus || 'not_started',
        stats: {
          ...data.stats,
          lastSeenAt: data.stats?.lastSeenAt
            ? new Date(data.stats?.lastSeenAt * 1000)
            : null,
        },
      };

    case 'orders':
      return {
        ...data,
        serviceFee: `${Number(data.serviceFee).toFixed(2)}%`,
        id: data.clientReferenceId,
        pgName: data.pgDetail?.pgName,
        paymentStatus: paymentStatusTextMap[data.paymentStatus],
        ...(data.placedAt ? { placedAt: new Date(data.placedAt) } : {}),
        ...(data.adjustments?.serviceFee && {
          adjustments: {
            ...data.adjustments,
            serviceFee: `${Number(data.adjustments.serviceFee).toFixed(2)}%`,
          },
        }),
      };
    case 'billpayOrders':
      return {
        ...data,
        billAmount: `${Number(data.billAmount).toFixed(2)}`,
        id: data.billPayClientReferenceId,
        billPayStatus: paymentStatusTextMap[data.billPayStatus],
        ...(data.paidOn ? { paidOn: new Date(data.paidOn) } : {}),
      };

    case 'payees':
      return {
        ...data,
        payeeAccountType: data.payeeAccountType === 1 ? 'Bank' : 'Upi',
        createdAt: new Date(data.createdAt * 1000),
      };
    case 'cards':
      return {
        ...data,
        createdAt: new Date(data.createdAt),
        updatedAt: new Date(data.updatedAt),
      };
    case 'kyc':
      return {
        ...data,
        createdAt: new Date(data.createdAt * 1000),
        kycModuleTypeId: kycModuleTypeIdMap[data.kycModuleTypeId],
        kycTypeId: kycTypeIdMap[data.kycTypeId],
        validated: data.action === 'pass',
        ...((data.kycModuleTypeId === 7 ||
          data.kycModuleTypeId === 11 ||
          data.kycModuleTypeId === 12) && {
          idImageUrl: `data:image/png;base64,${data.idImageUrl}`,
        }),
      };
    case 'merchantCodes':
      const { pgId, merchantId, merchantCode, ...pgRest } = data;
      return {
        ...pgRest,
        createdAt: new Date(data.createdAt * 1000),
        updatedAt: new Date(data.updatedAt * 1000),
        // paymentGateway: PaymentGateways[pgId],
        merchantId,
        id: merchantCode,
        ...mids[merchantCode],
      };
    case 'paymentTypes':
    case 'settlementTypes':
    case 'cardTypes':
    case 'cardBankTypes':
      const { enabled = true, ...ptRest } = data;
      return {
        ...ptRest,
        enabled,
      };
    case 'rangeTypes':
      return {
        ...data,
        name: data.desc,
      };
    case 'merchantCodeMapping':
      return data;

    case 'userConfigs':
      return {
        ...data,
        createdAt: new Date(data.createdAt * 1000),
        updatedAt: new Date(data.updatedAt * 1000),
        settlementTypeList: data.settlementTypeList
          ? data.settlementTypeList?.map((st: any) => ({
              ...st,
              isActive:
                typeof st.active === 'undefined' ? st.isActive : st.active,
            }))
          : [],
        id: data.userId,
      };
    case 'categories':
    case 'products':
    case 'reviews':
    case 'invoices':
    case 'notes':
      return { ...data, id: data._id };
    case 'user/rateLimits':
      return {
        ...data,
        createdAt: new Date(data.createdAt * 1000),
        updatedAt: new Date(data.updatedAt * 1000),
      };
    case 'apis/rateLimits':
      return {
        ...data,
        createdAt: new Date(data.createdAt * 1000),
        updatedAt: new Date(data.updatedAt * 1000),
      };

    default:
      throw new Error(`Unknown resource ${resource}`);
  }
};
export default formatData;
