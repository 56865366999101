import { useState, useCallback } from "react";
import { apiUrl, cardDetailsKey } from "../constants";
import { Notification, useNotify } from "react-admin";

type CardInformation = {
	id: string;
	name: string;
	last4Digit: string;
	userId: string;
	frontImageUrl: string;
	bin?: string;
	last4?: string;
	type?: string;
	international?: boolean;
	entity?: string;
	issuer?: string;
	network?: string;
	emi?: boolean;
	sub_type?: string;
	bankInfo?: {
		logo?: string;
		name?: string;
	};
	cardProvidersInfo?: {
		logo?: string;
		name?: string;
	};
};

const useCardInformation = () => {
	const notify = useNotify();
	const [cardInformation, setCardInformation] = useState<CardInformation | null>(null);
	const [cardInformationLoading, setCardInformationLoading] = useState(false);

	const fetchCardInformation = useCallback(async (selectedCardId: string) => {
		if (!selectedCardId) {
			console.log('No selectedCardId provided');
			return;
		}
		
		try {
			setCardInformationLoading(true);
			console.log('Fetching card info for ID:', selectedCardId);
			
			const response = await fetch(`${apiUrl}/cards/${selectedCardId}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: localStorage.getItem("auth") || "",
					privateKey: cardDetailsKey || "",
				} as Record<string, string>,
			});
			
			const res = await response.json();
			
			
			if (res.code === "MSG_0027" && res.httpStatus === "OK") {
				console.log('Setting card information:', res.data);
				setCardInformation(res.data);
			} else {
				console.log('API Error:', res);
				notify(res.description || 'Failed to fetch card information', { type: "error" });
			}
		} catch (e) {
			console.error('Error in fetchCardInformation:', e);
			notify('Error fetching card information', { type: "error" });
		} finally {
			setCardInformationLoading(false);
		}
	}, [notify]);

	const reset = useCallback(() => {
		setCardInformation(null);
	}, []);

	return { cardInformation, cardInformationLoading, fetchCardInformation, reset };
};

export default useCardInformation;
