import * as React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceField,
  SearchInput,
  TextField,
  downloadCSV,
  UrlField,
  NumberInput,
  SelectInput,
  DateTimeInput,
  FunctionField,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import jsonExport from 'jsonexport/dist';

// import SegmentsField from './SegmentsField';
// import SegmentInput from './SegmentInput';
import ColoredNumberField from './ColoredNumberField';
import MobileGrid from './MobileGrid';
import CustomerListAside from './CustomerListAside';
import FullNameField from './FullNameField';
import { Customer } from '../types';

const customerFilters = [
  <SearchInput source="q" />,
  <DateTimeInput label="Registered after" source="createdAtGTE" />,
  <DateTimeInput label="Registered before" source="createdAtLTE" />,
  <DateTimeInput label="Lastseen after" source="lastSeenAtGTE" />,
  <DateTimeInput label="Lastseen before" source="lastSeenAtLTE" />,
  <NumberInput label="Orders above" source="totalOrdersGTE" />,
  <NumberInput label="Orders below" source="totalOrdersLTE" />,
  // <NullableBooleanInput label="Card Selection Required" source="cardSelectionRequired" />,
  <SelectInput
    source="kycStatus"
    choices={[
      {
        id: 'auto_approved',
        name: 'Auto Approved',
      },
      {
        id: 'manually_approved',
        name: 'Manually Approved',
      },
      {
        id: 'manually_declined',
        name: 'Manually Declined',
      },
      {
        id: 'needs_review',
        name: 'Needs Review',
      },
      {
        id: 'user_cancelled',
        name: 'Incomplete',
      },
      {
        id: 'not_started',
        name: 'Not Started',
      },
      {
        id: 'error',
        name: 'Error',
      },
    ]}
  />,
];

const exporter = (customers: Customer[]) => {
  const customersForExport = customers.map((customer) => {
    const {
      stats,
      personalDetails: { profileImage, ...details },
      id,
      status,
      createdAt,
      kycCompleted,
      cardSelectionRequired,
    } = customer;
    return {
      id,
      ...details,
      ...stats,
      registeredAt: createdAt,
      kycCompleted: kycCompleted ? 'Yes' : 'No',
      status,
      cardSelectionRequired: cardSelectionRequired ? 'Yes' : 'No',
    };
  });
  jsonExport(
    customersForExport,
    {
      headers: ['id'], // order fields in the export
    },
    (_err: any, csv: any) => {
      downloadCSV(csv, 'customers'); // download as 'customers.csv` file
    }
  );
};

const CustomerList = () => {
  return (
    <List
      // filters={isSmall ? customerFilters : undefined}
      filters={customerFilters}
      sort={{ field: 'lastSeenAt', order: 'DESC' }}
      perPage={25}
      aside={<CustomerListAside />}
      exporter={exporter}
    >
      <Datagrid
        bulkActionButtons={false}
        optimized
        rowClick="show"
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        rowSx={(record, index) => ({
          '& .MuiTableCell-root': {
            whiteSpace: 'nowrap',
          },
        })}
      >
        {/* <CustomerReferenceField link={false} /> */}
        <FullNameField label="Name" />
        <DateField
          source="stats.lastSeenAt"
          label="Last Seen"
          showTime
          options={{
            month: 'short',
            day: 'numeric',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
        <TextField source="userId" label="User ID" />
        <NumberField source="stats.totalOrders" label="Orders" />

        <FunctionField
          source="cardSelectionRequired"
          label="Card Selection"
          // sortBy="cardSelectionRequired"
          sortable={false}
          render={(record: any) => (
            <div style={{ textAlign: 'center' }}>
              {record.cardSelectionRequired ? (
                <CheckIcon fontSize="small" />
              ) : (
                <CloseIcon fontSize="small" />
              )}
            </div>
          )}
        />

        <ColoredNumberField
          label="Revenue"
          source="stats.totalVolume"
          options={{ style: 'currency', currency: 'INR' }}
        />
      </Datagrid>
    </List>
  );
};

export default CustomerList;
