import { Stack, Button, Input, IconButton, Tooltip } from '@mui/material';
import { useCallback, useState } from 'react';
import {
  Labeled,
  TextField,
  useNotify,
  useRecordContext,
  useRefresh,
  FunctionField,
  Link,
} from 'react-admin';
import { Order } from '../types';
import { apiUrl } from '../constants';
import { CopyToClipboard } from '../components/CopyToClipboardWrapper';
import { ContentCopy } from '@mui/icons-material';

const AddManualLink = () => {
  const record = useRecordContext<Order>();
  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(false);

  const refresh = useRefresh();
  const notify = useNotify();

  const addLink = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetch(
        `${apiUrl}/orders/${record?.clientReferenceId}/updatePaymentManualLink`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('auth') || '',
          },
          body: JSON.stringify({
            paymentLink: link,
          }),
        }
      ).then((res) => res.json());

      if (res.code === 'MSG_0037') {
        notify('Link added successfully', { type: 'success' });
        setLink('');
      } else {
        notify('Something went wrong', { type: 'error' });
      }

      setLoading(false);
      refresh();
      return res;
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [link]);

  if (!record) return null;

  const linkAmout =
    Math.floor(record.priceBreakUp.totalAmount) <= 100
      ? 101
      : Math.floor(record.priceBreakUp.totalAmount).toString().endsWith('00')
      ? Math.floor(record.priceBreakUp.totalAmount) - 1
      : Math.floor(record.priceBreakUp.totalAmount);

  if (record?.manualPaymentLink) {
    return (
      <Stack spacing={1}>
        <Labeled source="manualPaymentLink" label="Payment Link">
          <Link to={record.manualPaymentLink} target="_blank">
            <TextField source="manualPaymentLink" emptyText="Not Available" />
          </Link>
        </Labeled>
        <Labeled source="paymentLinkRequested" label="WhatsApp Number">
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              source="paymentLinkRequested"
              emptyText="Not Available"
            />
            <CopyToClipboard
              text={`91${record?.paymentLinkRequested || ''}`}
              onCopy={() =>
                notify(
                  `Copied WhatsApp Number: 91${
                    record?.paymentLinkRequested || ''
                  }`
                )
              }
            >
              <Tooltip title="Copy WhatsApp Number">
                <IconButton size="small" sx={{ transform: 'rotateX(180deg)' }}>
                  <ContentCopy fontSize="small" />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>
        </Labeled>
        <Labeled source="clientReferenceId" label="Button URL">
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField source="clientReferenceId" emptyText="Not Available" />
            <CopyToClipboard
              text={record?.clientReferenceId}
              onCopy={() =>
                notify(`Copied Button URL: ${record?.clientReferenceId}`)
              }
            >
              <Tooltip title="Copy Button URL">
                <IconButton size="small" sx={{ transform: 'rotateX(180deg)' }}>
                  <ContentCopy fontSize="small" />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>
        </Labeled>
        <Labeled source="clientReferenceId" label="Link Amount">
          <Stack direction="row" alignItems="center" spacing={1}>
            <FunctionField
              source="priceBreakUp.totalAmount"
              render={(r: any) => {
                return linkAmout;
              }}
              options={{
                style: 'currency',
                currency: 'INR',
              }}
              sx={{ fontSize: '1.5rem' }}
            />
            <CopyToClipboard
              text={linkAmout.toString()}
              onCopy={() =>
                notify(`Copied Link Amount: ${linkAmout.toString()}`)
              }
            >
              <Tooltip title="Copy Link Amount">
                <IconButton size="small" sx={{ transform: 'rotateX(180deg)' }}>
                  <ContentCopy fontSize="small" />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>
        </Labeled>
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={1} my={2}>
      <Labeled label="Add Payment Link">
        <Input
          size="small"
          disabled={loading}
          value={link}
          onChange={(e) => setLink(e.target.value)}
          // sx={{ width: '300px' }}
        />
      </Labeled>

      <Button
        sx={{ width: '100px', alignSelf: 'flex-end' }}
        variant="contained"
        disabled={loading || !link}
        onClick={addLink}
      >
        Add Link
      </Button>
      <Labeled source="paymentLinkRequested" label="WhatsApp Number">
        <TextField source="paymentLinkRequested" emptyText="Not Available" />
      </Labeled>
      <Labeled source="clientReferenceId" label="Link Amount">
        <FunctionField
          source="priceBreakUp.totalAmount"
          render={(r: any) => {
            return Math.floor(r.priceBreakUp.totalAmount) <= 100
              ? 101
              : Math.floor(r.priceBreakUp.totalAmount).toString().endsWith('00')
              ? Math.floor(r.priceBreakUp.totalAmount) - 1
              : Math.floor(r.priceBreakUp.totalAmount);
          }}
          options={{
            style: 'currency',
            currency: 'INR',
          }}
          sx={{ fontSize: '1.5rem' }}
        />
      </Labeled>
    </Stack>
  );
};

export default AddManualLink;
