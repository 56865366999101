import {
	AutocompleteInput,
	DateField,
	Datagrid,
	ExportButton,
	FilterButton,
	List,
	NumberField,
	ReferenceInput,
	TextField,
	NumberInput,
	TopToolbar,
	useListContext,
	downloadCSV,
	SelectInput,
	DateTimeInput,
	FunctionField,
	SearchInput,
	CreateButton,
} from "react-admin";
import { Typography, Stack } from "@mui/material";
import CustomerReferenceField from "../customers/CustomerReferenceField";
import { Card, cardStatusTitles, Customer, Order } from "../types";
import jsonExport from "jsonexport/dist";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import { WarningAmberOutlined, CheckCircle, PendingOutlined } from "@mui/icons-material";
import CardListAside from "./CardListAside";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export const renderCardStatus = (value: any) => {
	return (
		<Stack direction="row" alignItems="center" spacing={0.5}>
			{value.cardStatusId === 2 && <CheckCircleOutline fontSize="small" />}
			{value.cardStatusId === 1 && <PendingOutlined fontSize="small" color="warning" />}
			{value.cardStatusId === 3 && <WarningAmberOutlined fontSize="small" color="error" />}
			<Typography variant="body2">{cardStatusTitles[value.cardStatusId]}</Typography>
		</Stack>
	);
};

const ListActions = () => (
	<TopToolbar>
		{/* <SelectColumnsButton /> */}
		<CreateButton />
		<FilterButton />
		{/* // <ExportButton /> */}
	</TopToolbar>
);

// const CardList = () => (
//   <List
//     filterDefaultValues={{ paymentStatus: 'RECEIVED' }}
//     sort={{ field: 'date', order: 'DESC' }}
//     perPage={25}
//     filters={orderFilters}
//     actions={<ListActions />}
//   >
//     <TabbedDatagrid />
//   </List>
// );

const paymentStatusChoices = [
	{ id: "RECEIVED", name: "Received" },
	{ id: "SETTLEMENT_INITIATED", name: "Settlement Initiated" },
	{ id: "SETTLEMENT_COMPLETED", name: "Settled" },
	{ id: "FAILED", name: "Failed" },
	{ id: "CANCELLED", name: "Cancelled" },
	{ id: "INITIATED", name: "Incomplete" },
];

const orderFilters = [
	// <SearchInput source="q" />,
	<ReferenceInput label="Customers" source="userId" reference="customers">
		<AutocompleteInput
			optionText={(choice?: Customer) =>
				choice?.id // the empty choice is { id: '' }
					? `${choice?.personalDetails?.firstName} ${choice?.personalDetails?.lastName}`
					: ""
			}
			sx={{ minWidth: 200 }}
		/>
	</ReferenceInput>,
	<DateTimeInput label="Created After" source="createdAtGTE" />,
	<DateTimeInput label="Created Before" source="createdAtLTE" />,
	<ReferenceInput label="Card Type" source="cardType" reference="cardTypes">
		<SelectInput optionText="name" />
	</ReferenceInput>,
	<SelectInput
		label="Step Count"
		source="stepCount"
		choices={[
			{ id: 0, name: "Step 1" },
			{ id: 1, name: "Step 2" },
			{ id: 2, name: "Step 3" },
		]}
	/>,

	<SelectInput
		label="Card Status"
		source="cardStatusId"
		choices={[
			{ id: 1, name: "Pending" },
			{ id: 2, name: "Approved" },
			{ id: 3, name: "Rejected" },
		]}
	/>,
	// selectinput for filtering based on unlisted
	<SelectInput
		label="Active"
		source="unlisted"
		choices={[
			{ id: true, name: "No" },
			{ id: false, name: "Yes" },
		]}
	/>,

	<ReferenceInput label="Card Provider" source="cardProviderId" reference="cardProviders">
		<AutocompleteInput
			optionText={(choice?: any) =>
				choice?.id // the empty choice is { id: '' }
					? `${choice?.name}`
					: ""
			}
			sx={{ minWidth: 200 }}
			optionValue="dbId"
		/>
	</ReferenceInput>,

	<ReferenceInput label="Bank" source="bankId" reference="banks">
		<AutocompleteInput
			optionText={(choice?: any) =>
				choice?.id // the empty choice is { id: '' }
					? `${choice?.name}`
					: ""
			}
			sx={{ minWidth: 200 }}
			optionValue="dbId"
		/>
	</ReferenceInput>,

	// <NumberInput label="Total Above" source="settlementAmountGTE" />,
	// <NumberInput label="Total Below" source="settlementAmountLTE" />,
	// <SelectInput
	//   label="Payment Status"
	//   source="paymentStatus"
	//   choices={paymentStatusChoices}
	// />,
	// <DateTimeInput label="Settlement After" source="settlementByGTE" />,
	// <DateTimeInput label="Settlement Before" source="settlementByLTE" />,

	//   <FilterList label="Step Count" icon={<AccessTimeIcon />}>
	//   <FilterListItem label="Step 1" value={{ stepCount: 0 }} />
	//   <FilterListItem label="Step 2" value={{ stepCount: 1 }} />
	//   <FilterListItem label="Step 3" value={{ stepCount: 2 }} />
	// </FilterList>
	// above like filtering for the filterlistbutton
];

const exporter = (orders: Order[]) => {
	const ordersForExport = orders.map((order) => {
		const {
			id,
			priceBreakUp,
			// payeeInfo: { payeeNickName, payerName, ...payee },
			clientPaymentId,
			clientReferenceId,
			currency,
			guestDetail,
			updatedAt,
			...rest
		} = order;
		return {
			...priceBreakUp,
			// ...payee,
			...rest,
			id,
		};
	});
	jsonExport(
		ordersForExport,
		{
			headers: ["id"], // order fields in the export
		},
		(_err: any, csv: any) => {
			downloadCSV(csv, "orders"); // download as 'orders.csv` file
		}
	);
};

const CardListContent = () => {
	const { filterValues, setFilters, displayedFilters, ...rest } = useListContext();
	
	return (
		<Datagrid
			rowSx={(record, index) => ({
				opacity: record.cardStatusId === 3 || (record.cardStatusId === 1 && record.stepCount !== 2) ? 0.5 : 1,
				"& .MuiTableCell-root": {
					whiteSpace: "nowrap",
					fontWeight: record.cardStatusId === 1 && record.stepCount === 2 ? "bold" : 400,
				},
				"& *": {
					fontWeight: record.cardStatusId === 1 && record.stepCount === 2 ? "bold !important" : 400,
				},
			})}
			bulkActionButtons={false}
			rowClick="show"
			sx={{ opacity: 1 }}
		>
			<DateField
				source="createdAt"
				showTime
				options={{
					month: "short",
					day: "numeric",
					hour12: true,
					hour: "numeric",
					minute: "numeric",
				}}
			/>
			<FunctionField source="cardStatusId" label="Status" render={renderCardStatus} />
			<CustomerReferenceField link={false} />
			<TextField source="name" />
			<FunctionField
				source="stepCount"
				render={(record: any) => (record.stepCount === 0 ? "Step 1" : record.stepCount === 1 ? "Step 2" : "Step 3")}
			/>

			<FunctionField
				source="unlisted"
				label="Active"
				sortable={false}
				render={(record: any) => (record.unlisted ? <CloseIcon fontSize="small" /> : <CheckIcon fontSize="small" />)}
			/>

			<TextField source="last4Digit" />
		</Datagrid>
	);
};

const CardList = () => {
	return (
		<>
			<List
				sort={{ field: "createdAt", order: "DESC" }}
				perPage={25}
				filters={orderFilters}
				actions={<ListActions />}
				aside={<CardListAside />}
				exporter={exporter}
			>
				<CardListContent />
			</List>
		</>
	);
};

export default CardList;
