import { useGetList } from 'react-admin';
import { Stack, Typography, Grid, useTheme, useMediaQuery } from '@mui/material';
import CardInformation from '../components/CardInformation';

interface CustomerCardListProps {
  userId: string;
  expanded: boolean;
}

const CustomerCardList = ({ userId, expanded }: CustomerCardListProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: cards = [] } = useGetList('cards', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'DESC' },
    filter: { userId },
  });

  if (!cards.length) {
    return <Typography>No cards found</Typography>;
  }

  return (
    <Grid container spacing={3}>
      {cards.map((card) => (
        <Grid 
          item 
          xs={12} 
          sm={6} 
          key={card.id}
          sx={{
            '& > div': {
              // Target the CardInformation root div
              width: '100% !important',
              '& > div': {
                // Target the inner card component
                width: '100% !important',
                maxWidth: '400px',
                margin: '0 auto',
                height: 'auto !important',
                '& button': {
                  // Hide the expand button
                  display: 'none'
                }
              }
            }
          }}
        >
          <CardInformation selectedCardId={card.id} forceExpanded={expanded} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CustomerCardList; 