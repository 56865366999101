import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import KYCIcon from '@mui/icons-material/FingerprintOutlined';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
} from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  startOfYesterday,
  addDays,
  subDays,
  endOfToday,
  startOfToday,
} from 'date-fns';

// import segments from '../segments/data';

const Aside = () => (
  <Card
    sx={{
      order: -1,
      flex: '0 0 15em',
      mr: 2,
      mt: 6,
      alignSelf: 'flex-start',
      height: '85vh',
      overflowY: 'auto',
    }}
  >
    <CardContent sx={{ pt: 1 }}>
      <FilterLiveSearch hiddenLabel />

      <SavedQueriesList />

      <FilterList label="Registered" icon={<AccessTimeIcon />}>
        <FilterListItem
          label="Today"
          value={{
            createdAtGTE: startOfToday().toISOString(),
            createdAtLTE: undefined,
          }}
        />
        <FilterListItem
          label="Yesterday"
          value={{
            createdAtLTE: endOfYesterday().toISOString(),
            createdAtGTE: startOfYesterday().toISOString(),
          }}
        />
        <FilterListItem
          label="2 Days Ago"
          value={{
            createdAtLTE: subDays(endOfToday(), 2).toISOString(),
            createdAtGTE: subDays(startOfToday(), 2).toISOString(),
          }}
        />
        <FilterListItem
          label="3 Days Ago"
          value={{
            createdAtLTE: subDays(endOfToday(), 3).toISOString(),
            createdAtGTE: subDays(startOfToday(), 3).toISOString(),
          }}
        />
        <FilterListItem
          label="4 Days Ago"
          value={{
            createdAtLTE: subDays(endOfToday(), 4).toISOString(),
            createdAtGTE: subDays(startOfToday(), 4).toISOString(),
          }}
        />
        <FilterListItem
          label="5 Days Ago"
          value={{
            createdAtLTE: subDays(endOfToday(), 5).toISOString(),
            createdAtGTE: subDays(startOfToday(), 5).toISOString(),
          }}
        />
        <FilterListItem
          label="This Week"
          value={{
            createdAtGTE: startOfWeek(new Date()).toISOString(),
            createdAtLTE: undefined,
          }}
        />
        <FilterListItem
          label="Last Week"
          value={{
            createdAtGTE: subWeeks(startOfWeek(new Date()), 1).toISOString(),
            createdAtLTE: startOfWeek(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="This Month"
          value={{
            createdAtGTE: startOfMonth(new Date()).toISOString(),
            createdAtLTE: undefined,
          }}
        />
        <FilterListItem
          label="Last Month"
          value={{
            createdAtGTE: subMonths(startOfMonth(new Date()), 1).toISOString(),
            createdAtLTE: startOfMonth(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="Earlier"
          value={{
            createdAtGTE: undefined,
            createdAtLTE: subMonths(startOfMonth(new Date()), 1).toISOString(),
          }}
        />
      </FilterList>

      <FilterList label="Last Seen" icon={<AccessTimeIcon />}>
        <FilterListItem
          label="Today"
          value={{
            lastSeenAtGTE: endOfYesterday().toISOString(),
            lastSeenAtLTE: undefined,
          }}
        />
        <FilterListItem
          label="This Week"
          value={{
            lastSeenAtGTE: startOfWeek(new Date()).toISOString(),
            lastSeenAtLTE: undefined,
          }}
        />
        <FilterListItem
          label="Last Week"
          value={{
            lastSeenAtGTE: subWeeks(startOfWeek(new Date()), 1).toISOString(),
            lastSeenAtLTE: startOfWeek(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="This Month"
          value={{
            lastSeenAtGTE: startOfMonth(new Date()).toISOString(),
            lastSeenAtLTE: undefined,
          }}
        />
        <FilterListItem
          label="Last Month"
          value={{
            lastSeenAtGTE: subMonths(startOfMonth(new Date()), 1).toISOString(),
            lastSeenAtLTE: startOfMonth(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="Earlier"
          value={{
            lastSeenAtGTE: undefined,
            lastSeenAtLTE: subMonths(startOfMonth(new Date()), 1).toISOString(),
          }}
        />
      </FilterList>

      <FilterList label="Has Ordered" icon={<CurrencyRupeeIcon />}>
        <FilterListItem
          label="ra.boolean.true"
          value={{
            totalOrdersGTE: 1,
            totalOrdersLTE: undefined,
          }}
        />
        <FilterListItem
          label="ra.boolean.false"
          value={{
            totalOrdersGTE: undefined,
            totalOrdersLTE: 0,
          }}
        />
      </FilterList>

      <FilterList label="Card Selection Required" icon={<KYCIcon />}>
        <FilterListItem
          label="ra.boolean.true"
          value={{
            cardSelectionRequired: true,
          }}
        />
        <FilterListItem
          label="ra.boolean.false"
          value={{
            cardSelectionRequired: false,
          }}
        />
      </FilterList>

      <FilterList label="KYC Status" icon={<KYCIcon />}>
        <FilterListItem
          label="Auto Approved"
          value={{ kycStatus: 'auto_approved' }}
        />
        <FilterListItem
          label="Manually Approved"
          value={{ kycStatus: 'manually_approved' }}
        />
        <FilterListItem
          label="Manually Declined"
          value={{ kycStatus: 'manually_declined' }}
        />
        <FilterListItem
          label="Needs Review"
          value={{ kycStatus: 'needs_review' }}
        />
        <FilterListItem
          label="Incomplete"
          value={{ kycStatus: 'user_cancelled' }}
        />
        <FilterListItem
          label="Not Started"
          value={{ kycStatus: 'not_started' }}
        />
        <FilterListItem label="Error" value={{ kycStatus: 'error' }} />
      </FilterList>

      {/* <FilterList
        label="resources.customers.filters.group"
        icon={<LocalOfferIcon />}
      >
        {segments.map((segment) => (
          <FilterListItem
            label={segment.name}
            key={segment.id}
            value={{ groups: segment.id }}
          />
        ))}
      </FilterList> */}
    </CardContent>
  </Card>
);

export default Aside;
