import { Card, CardContent } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { AutocompleteInput, FilterList, FilterListItem, FilterLiveSearch, ReferenceInput, SavedQueriesList, SelectInput, Form, useListContext } from "react-admin";
import { endOfYesterday, startOfWeek, subWeeks, startOfMonth, subMonths, addHours, startOfHour, endOfDay, addDays, startOfToday } from "date-fns";
import { useGetList } from "react-admin";
import { Customer } from "../types";

interface CardProviderFilter {
	cardProviderId: string | number;
}

const Aside = () => {
	const { filterValues } = useListContext();
	
	const { data: cardProviders, isLoading } = useGetList("cardProviders", {
		pagination: { page: 1, perPage: 100 },
		sort: { field: "name", order: "ASC" },
	});

	const { data: cardTypes, isLoading: isCardTypesLoading } = useGetList("cardTypes", {
		pagination: { page: 1, perPage: 100 },
		sort: { field: "name", order: "ASC" },
	});

	// Only fetch cardBankTypes when cardType is selected
	const { data: cardBankTypes, isLoading: isCardBankTypesLoading } = useGetList(
		"cardBankTypes",
		{
			pagination: { page: 1, perPage: 100 },
			sort: { field: "name", order: "ASC" },
		}
	);

	// Filter cardBankTypes based on selected cardType
	const filteredCardBankTypes = cardBankTypes?.filter(bankType => {
		return bankType.cardTypeId === filterValues?.cardType;
	});		

	// Show Card Bank Types only when cardType is selected and filtered results exist
	const showCardBankTypes = !!filterValues?.cardType && !isCardBankTypesLoading && filteredCardBankTypes && filteredCardBankTypes.length > 0;	

	return (
		<Card
			sx={{
				order: -1,
				flex: "0 0 15em",
				mr: 2,
				mt: 6,
				alignSelf: "flex-start",
				height: "85vh",
				overflowY: "auto",
			}}
		>
			<CardContent sx={{ pt: 1 }}>
				<FilterLiveSearch hiddenLabel />
				<SavedQueriesList />

				{/* filter based on Card Type (CREDIT/CORPORATE/PREPAID) */}
				<FilterList label="Card Type" icon={<CheckCircleOutlineIcon />}>
					{!isCardTypesLoading &&
						cardTypes &&
						cardTypes.map((type) => {
							return <FilterListItem key={type.id} label={type.name} value={{ cardType: type.id }} />;
						})}
				</FilterList>

				{/* Show Card Bank Types only when cardType is selected */}
				{showCardBankTypes && (
					<FilterList label="Card Bank Type" icon={<CheckCircleOutlineIcon />}>
						{filteredCardBankTypes.map((bankType) => {
							return <FilterListItem key={bankType.id} label={bankType.name} value={{ cardBankType: bankType.id }} />;
						})}
					</FilterList>
				)}

				{/* Unlisted based filter */}
				<FilterList label="Active" icon={<CheckCircleOutlineIcon />}>
					<FilterListItem label="ra.boolean.true" value={{ unlisted: false }} />
					<FilterListItem label="ra.boolean.false" value={{ unlisted: true }} />
				</FilterList>

				{/* Card status codes:  1 - Pending, 2 - Successfully approved, 3 - Rejected */}
				<FilterList label="Card Status" icon={<CheckCircleOutlineIcon />}>
					<FilterListItem label="Pending" value={{ cardStatusId: 1 }} />
					<FilterListItem label="Successfully approved" value={{ cardStatusId: 2 }} />
					<FilterListItem label="Rejected" value={{ cardStatusId: 3 }} />
				</FilterList>

				{/* filter based on card provider */}
				<FilterList label="Card Provider" icon={<CheckCircleOutlineIcon />}>
					{!isLoading &&
						cardProviders &&
						cardProviders.map((provider) => {
							const filterValue = {
								cardProviderId: provider.dbId,
							};
							return <FilterListItem key={provider.id} label={provider.name} value={filterValue} />;
						})}
				</FilterList>

				{/* filter based on stepCount */}
				<FilterList label="Step Count" icon={<AccessTimeIcon />}>
					<FilterListItem label="Step 1" value={{ stepCount: 0 }} />
					<FilterListItem label="Step 2" value={{ stepCount: 1 }} />
					<FilterListItem label="Step 3" value={{ stepCount: 2 }} />
				</FilterList>

				{/* filter based on card creation date */}
				<FilterList label="Card Creation Date" icon={<AccessTimeIcon />}>
					<FilterListItem
						label="Today"
						value={{
							createdAtGTE: startOfToday().toISOString(),
							createdAtLTE: undefined,
						}}
					/>
					<FilterListItem
						label="Yesterday"
						value={{
							createdAtGTE: endOfYesterday().toISOString(),
							createdAtLTE: undefined,
						}}
					/>
					<FilterListItem
						label="This week"
						value={{
							createdAtGTE: startOfWeek(new Date()).toISOString(),
							createdAtLTE: undefined,
						}}
					/>
					<FilterListItem
						label="This month"
						value={{
							createdAtGTE: startOfMonth(new Date()).toISOString(),
							createdAtLTE: undefined,
						}}
					/>
				</FilterList>
			</CardContent>
		</Card>
	);
};

export default Aside;
