import {
  Button,
  Stack,
  Box,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';
import { CopyToClipboard } from '../components/CopyToClipboardWrapper';
import { apiUrl } from '../constants';
import ContentCopy from '@mui/icons-material/ContentCopy';
import IDCopy from '@mui/icons-material/CopyAll';
import LinkIcon from '@mui/icons-material/Link';

const generate = async ({ customerId }: { customerId: string }) => {
  const response = await fetch(
    `${apiUrl}/customers/${customerId}/generateKycLink`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('auth') || '',
      },
    }
  ).then((res) => res.json());

  return response.data;
};

const GenerateKYCLink = ({ customerId }: { customerId: string }) => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const handleGenerateKYCLink = async () => {
    await generate({ customerId });
    refresh();
  };

  const link = record?.kycUrl || '';
  return (
    <Box display="block" mt={2}>
      {link ? (
        <Stack spacing={2}>
          <Stack direction="column" spacing={1}>
            <Typography variant="h6">KYC Link:</Typography>
            <Stack direction="row" alignItems="center" spacing={2} pr={6}>
              <Typography variant="body2">{link}</Typography>
              <CopyToClipboard
                text={
                  link.replace(
                    'https://link-kyc.idv.hyperverge.co/?identifier=',
                    ''
                  ) || ''
                }
                onCopy={() =>
                  notify(
                    `Copied ID: ${
                      link.replace(
                        'https://link-kyc.idv.hyperverge.co/?identifier=',
                        ''
                      ) || ''
                    }`
                  )
                }
              >
                <Tooltip title="Copy Link ID">
                  <IconButton>
                    <IDCopy fontSize="small" />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
              <CopyToClipboard
                text={link}
                onCopy={() => notify(`Copied Link: ${link}`)}
              >
                <Tooltip title="Copy Full KYC Link">
                  <IconButton sx={{ transform: 'rotateX(180deg)' }}>
                    <ContentCopy fontSize="small" />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Button
          startIcon={<LinkIcon />}
          variant="outlined"
          color="inherit"
          disabled={!!link}
          onClick={handleGenerateKYCLink}
        >
          Get KYC Link
        </Button>
      )}
    </Box>
  );
};

export default GenerateKYCLink;
