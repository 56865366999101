import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  useTranslate,
  ArrayField,
  Datagrid,
  TextField,
  BooleanField,
  Labeled,
  useGetOne,
  useShowController,
  Link,
  usePermissions,
  useGetList,
  PrevNextButtons,
} from 'react-admin';
import {
  Grid,
  Box,
  Typography,
  Stack,
  Chip,
  Theme,
  useMediaQuery,
  Card,
  CardContent,
  IconButton,
  Collapse,
  styled,
  IconButtonProps,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Aside from './Aside';
import FullNameField from './FullNameField';
import UserConfigEdit from '../configs/UserConfigEdit';
import UserConfigCreate from '../configs/UserConfigCreate';
import UserConfigView from '../configs/UserConfigView';
import GenerateKYCLink from './GenerateKYCLink';
import RegenerateKYC from './RegenerateKYC';
import ApproveKYC from './ApproveKYC';
import { KYCShowList } from '../kycDocs/KYCShow';
import AddKYCModules from './AddKYCModules';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CardSelectionRequired } from './CardSelectionRequired';
import CustomerCardList from './CustomerCardList';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CustomerShow = (props: any) => {
  const { permissions } = usePermissions();
  const { record } = useShowController();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { data, error } = useGetOne(
    'userConfigs',
    { id: record?.userId },
    {
      enabled: !!record?.userId,
    }
  );

  const { data: kycList = [] } = useGetList('kyc', {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'createdAt', order: 'DESC' },
    filter: { transactionId: record?.kycTransactionId, q: undefined },
  });

  const aadhaarSelfieMatch = kycList.find(
    (kyc: any) =>
      kyc.kycModuleTypeId === 'Aadhaar & Selfie Match' && kyc.validated
  );

  const aadhaarPanMatch = kycList.find(
    (kyc: any) => kyc.kycModuleTypeId === 'Aadhaar & Pan Match' && kyc.validated
  );

  const selfie = kycList.find(
    (kyc: any) => kyc.kycModuleTypeId === 'Selfie Capture'
  );

  const aadhaar =
    kycList.find(
      (kyc: any) =>
        kyc.kycModuleTypeId === 'Aadhaar Digi Locker' && kyc.validated
    ) ||
    kycList.find(
      (kyc: any) => kyc.kycModuleTypeId === 'Aadhaar XML' && kyc.validated
    );

  const pan = kycList.find(
    (kyc: any) => kyc.kycModuleTypeId === 'PAN' && kyc.validated
  );

  const allModulesCompleted =
    selfie && aadhaar && pan && aadhaarPanMatch && aadhaarSelfieMatch;

  console.log('data?.settlementTypeList:', data?.settlementTypeList);

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Show
        component={'div'}
        title={<CustomerTitle />}
        {...(!isSmall && {
          aside: (
            <Box width={400}>
              <Aside />
            </Box>
          ),
        })}
      >
        <SimpleShowLayout sx={{ padding: 0 }}>
          <PrevNextButtons
            linkType="show"
            sort={{ field: 'lastSeenAt', order: 'DESC' }}
          />
          <Card>
            <CardContent>
              <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
                <Grid item md={12} minWidth="100%">
                  <Typography variant="h6" gutterBottom>
                    Personal Details
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="userId" label="User ID">
                        <TextField source="userId" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled label="Name">
                        <Stack direction="row" spacing={0.5}>
                          <TextField source="personalDetails.firstName" />
                          <TextField source="personalDetails.lastName" />
                        </Stack>
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} mt={1}>
                      <Labeled
                        label={<OtpLabel param={'sms'} record={record} />}
                      >
                        <TextField source="personalDetails.mobile" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} mt={1}>
                      <Labeled
                        label={<OtpLabel param={'email'} record={record} />}
                      >
                        <TextField source="personalDetails.email" />
                      </Labeled>
                    </Grid>
                  </Grid>
                  <CardSelectionRequired record={record} />
                  <Box mt="1em" />

                  <Typography variant="h6" gutterBottom>
                    KYC Details
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled label="KYC Transaction ID">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <TextField source="kycTransactionId" />
                          <RegenerateKYC customerId={record?.userId} />
                        </Stack>
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled source="kycStatus" label="KYC Status">
                        <TextField source="kycStatus" />
                      </Labeled>
                    </Grid>
                  </Grid>
                  <>
                    {kycList.length > 0 && (
                      <Stack flex={1} mt={2} mb={2}>
                        <KYCShowList transactionId={record?.kycTransactionId} />
                      </Stack>
                    )}
                    {kycList.length > 0 && (
                      <AddKYCModules
                        kycTransactionId={record?.kycTransactionId}
                        aadhaar={aadhaar}
                        pan={pan}
                        selfie={selfie}
                        aadhaarPan={aadhaarPanMatch}
                        aadhaarSelfie={aadhaarSelfieMatch}
                      />
                    )}
                    {allModulesCompleted && (
                      <ApproveKYC
                        customerId={record?.userId}
                        kycTransactionId={record?.kycTransactionId}
                      />
                    )}
                    {(record?.kycStatus === 'not_started' ||
                      record?.kycStatus === 'user_cancelled') && (
                      <GenerateKYCLink customerId={record?.userId} />
                    )}
                  </>
                  {/* <ArrayField source="kycDocument">
              <Datagrid bulkActionButtons={false}>
                <TextField source="docType" label="Doc Type" />
                <TextField source="customerName" label="Name" />
                <TextField source="docNumber" label="Number" />
                <BooleanField source="kycValidated" label="Validated" />
              </Datagrid>
            </ArrayField> */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </SimpleShowLayout>
        <Box mt="1em" />

        <Card sx={{ width: '100%', mb: 2 }}>
          <CardContent>
            <Stack direction="row" alignItems="center" width="100%">
              <Typography variant="h6">Customer Cards</Typography>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show cards"
                size="small"
              >
                <ExpandMoreIcon fontSize="small" />
              </ExpandMore>
            </Stack>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Box 
                sx={{ 
                  width: '100%',
                  maxWidth: { xs: '100%', xl: '900px' },
                  mx: 'auto',                
                  px: { xs: 0, sm: 1, md: 2 },
                  mt: 2
                }}
              >
                <CustomerCardList userId={record?.userId} expanded={expanded} />
              </Box>
            </Collapse>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            {!permissions?.includes('user_config_edit') &&
              permissions?.includes('user_config_view') && <UserConfigView />}

            {permissions?.includes('user_config_edit') &&
              (data?.id ? (
                <UserConfigEdit userId={record?.userId} />
              ) : (
                <UserConfigCreate userId={record?.userId} />
              ))}
          </CardContent>
        </Card>
        {isSmall && (
          <Box px={1}>
            <Aside />
          </Box>
        )}
      </Show>
    </>
  );
};

const CustomerTitle = () => (
  <FullNameField size="32" sx={{ margin: '5px 0' }} />
);

const OtpLabel = ({
  param,
  record,
}: {
  param: 'sms' | 'email';
  record: any;
}) => {
  const keys = {
    sms: {
      label: 'Mobile',
      validated: 'smsOtpValidated',
      otp: 'smsOtp',
    },
    email: {
      label: 'Email',
      validated: 'emailOtpValidated',
      otp: 'emailOtp',
    },
  };

  if (!record.otpInfo)
    return (
      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
        {keys[param]?.label}
      </span>
    );

  return (
    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
      {keys[param]?.label}
      {record.otpInfo[keys[param].validated] ? (
        <CheckCircleIcon color="primary" sx={{ height: '16px' }} />
      ) : (
        <Chip
          label={
            <>
              {' '}
              OTP <b> {record.otpInfo[keys[param].otp]}</b>
            </>
          }
          variant="outlined"
          style={{
            padding: '2px 0px',
            fontSize: '14px',
            height: '22px',
            background: '#00000011',
            marginLeft: '10px',
          }}
        ></Chip>
      )}
    </span>
  );
};

export default CustomerShow;
