import { ReactNode } from 'react';

interface CopyToClipboardProps {
  text: string;
  onCopy?: (text: string, result: boolean) => void;
  children: ReactNode;
}

export const CopyToClipboard = ({
  text,
  onCopy,
  children,
}: CopyToClipboardProps) => {
  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(text);
      onCopy?.(text, true);
    } catch (error) {
      onCopy?.(text, false);
    }
  };

  return (
    <div onClick={handleClick} style={{ cursor: 'pointer' }}>
      {children}
    </div>
  );
};
